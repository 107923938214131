// This extends jQuery validate, in case you were wondering

window.BMD = window.BMD || {};

BMD.custom_validators = (function($) {
  var phoneValidationFailedMessage,
      emailValidationFailedMessage,
      customCheckboxSelector,
      webValidationFailedMessage,

  init = function(options) {
    if (options) {
      phoneValidationFailedMessage = options.phoneMessage;
      emailValidationFailedMessage = options.emailMessage;
      webValidationFailedMessage = options.webMessage;
      customCheckboxSelector = options.customCheckboxSelector;
    }
  },

  phone = function() {
    $.validator.addMethod('phoneUK', function(value, element) {
            value = value.replace(/\s+|-|\(|\)/g,'');
            return this.optional(element) || !(value.match(/(\(?(0|\+44)[1-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})/));
    }, phoneValidationFailedMessage);

    $.validator.addMethod('mobileUK', function(value, element) {
            value = value.replace(/\s+|-|\(|\)/g,'');
            return this.optional(element) || !(value.match(/((0|\+44)7(0|4|5|6|7|8|9){1}\d{2}\s?\d{6})/));
    }, phoneValidationFailedMessage);

    //Matches UK landline + mobile, accepting only 01-3 for landline or 07 for mobile to exclude many premium numbers
    $.validator.addMethod('phonesUK', function(value, element) {
            value = value.replace(/\s+|-|\(|\)/g,'');
            return this.optional(element) || !(value.match(/(0[1-3]{1}[0-9]{8,9})$/) || value.match(/^(07[5-9]{1}[0-9]{7,8})/));
    }, phoneValidationFailedMessage);

    $.validator.addMethod('phoneNumInWords', function(value, element) {
      var nums = [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
        'zero', 'zeroh', 'cipher', 'null', 'naught', 'nought', 'love', 'duck', 'nil', 'nada', 'zilch', 'zip', 'o', 'oh', 'aught', 'ought',
        'one', 'two', 'too', 'three', 'four', 'for', 'five', 'fave', 'six', 'seven', 'eight', 'nine', 'nein',
        'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen',
        'twenty', 'thirty', 'forty', 'fourty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety', 'hundred', 'thousand',
        'and', 'plus', 'then', 'double', 'triple', 'tripple'
      ];

      var regex = new RegExp('(' + nums.join('|') + '){6,}');
      value = value.toLocaleLowerCase().replace(/[^a-z0-9]/g, '');
      return this.optional(element) || !value.match(regex);
    }, phoneValidationFailedMessage);
  },

  noEmail = function() {
    $.validator.addMethod("noEmail", function(value, element) {
      return this.optional(element) || !(value.match(/([\w+\-.]+|dot|full\s*stop)\s*(@|([\[({]at[\]})])|\bat\b)\s*([a-z\d\-.]+|dot|full\s*stop)\s*(\.|dot|full\s*stop)\s*(com|co\s*(\.|dot|full\s*stop)\s*uk)/ig));
    }, emailValidationFailedMessage);
  },

  noWeb = function() {
    webValidationFailedMessage = webValidationFailedMessage || "Please don't put website links in your profile. Your safety is important to us.";
    $.validator.addMethod("noWeb", function(value, element) {
        return this.optional(element) || !(value.match(/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([A-Za-z][-\d\w]{0,253}[\d\w]\.(?![A-Z]))+[\w]{2,4}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&amp;?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/));
    }, webValidationFailedMessage);
  },

  social = function() {
    $.validator.addMethod('twitter', function(value, element) {
            return this.optional(element) || !(value.match(/(\s*[and|or]*\s*twitter|\s*tweet)/i));
    }, "please don't put your contact details");

    $.validator.addMethod('facebook', function(value, element) {
            return this.optional(element) || !(value.match(/(\s*[and|or]*\s*facebook|\s*fbook\s*|\sfb)/i));
    }, "please don't put your contact details");
  },

  checkbox = function() {
    var checkboxes = customCheckboxSelector || $('input[type="checkbox"]');

    $.validator.addMethod('require-one', function(value) {
      return checkboxes.filter(':checked').size() > 0;
    }, 'Please select at least one option');

    var checkbox_names = $.map(checkboxes, function(e, i) {
      return $(e).attr("name");
    }).join(" ");

    $(document).on('click', checkboxes, function(){
      $('label.error').hide();
    });

    $('form').validate({
      groups: {
        checks: checkbox_names
      }
    });
  };


return {
  init: init,
    phone: phone,
    noEmail: noEmail,
    checkbox: checkbox,
    social: social,
    noWeb: noWeb
  };
})(jQuery);
