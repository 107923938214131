$.validator.addMethod( "require_from_group", function( value, element, options ) {
  var $fields = $( options[ 1 ], element.form ),
    $fieldsFirst = $fields.eq( 0 ),
    validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : $.extend( {}, this ),
    isValid = $fields.filter( function() {
      return validator.elementValue( this );
    } ).length >= options[ 0 ];

  // Store the cloned validator for future validation
  $fieldsFirst.data( "valid_req_grp", validator );

  // If element isn't being validated, run each require_from_group field's validation rules
  if ( !$( element ).data( "being_validated" ) ) {
    $fields.data( "being_validated", true );
    $fields.each( function() {
      validator.element( this );
    } );
    $fields.data( "being_validated", false );
  }
  return isValid;
}, $.validator.format( "Please fill at least {0} of these fields." ) );

$.validator.methods.email = function(value, element) {
  return this.optional(element) || (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
};
